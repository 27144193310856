@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "Niconne";
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(./fonts/Niconne-Regular.woff) format("woff");
    }

    @font-face {
        font-family: "ParkLaneNF";
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(./fonts/ParkLaneNF.woff) format("woff");
    }

    @font-face {
        font-family: "Semplicita";
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(./fonts/Semplicita\ Medium.woff) format("woff");
    }

    @font-face {
        font-family: "Semplicita";
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(./fonts/Semplicita\ Light.woff) format("woff");
    }

    @font-face {
        font-family: "Semplicita";
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url(./fonts/Semplicita\ Bold.woff) format("woff");
    }
}

body {
    color: #f7e09c;
    background-color: black;
    min-width: 340px;
}

.fill-screen {
    height: calc(max(100dvh));
}

.shimmer {
    mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/600% 100%;
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/600%
        100%;
    background-repeat: no-repeat;
    animation: shimmer 5s infinite;
}

@keyframes shimmer {
    100% {
        -webkit-mask-position: left;
    }
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.animate-fade-in {
    -webkit-animation: fadeInAnimation 3s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation: fadeInAnimation 3s;
    -moz-animation-fill-mode: forwards;
    -o-animation: fadeInAnimation 3s;
    -o-animation-fill-mode: forwards;
    animation: fadeInAnimation 3s;
    animation-fill-mode: forwards;
}

@keyframes fadeInThenOutAnimation {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }
    100% {
        opacity: 0;
    }
}
@-o-keyframes fadeInThenOutAnimation {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }
    100% {
        opacity: 0;
    }
}
@-moz-keyframes fadeInThenOutAnimation {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes fadeInThenOutAnimation {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }
    100% {
        opacity: 0;
    }
}
.animate-fade-in-then-out {
    -webkit-animation: fadeInThenOutAnimation 5s ease-in-out;
    -webkit-animation-fill-mode: forwards;
    -moz-animation: fadeInThenOutAnimation 5s ease-in-out;
    -moz-animation-fill-mode: forwards;
    -o-animation: fadeInThenOutAnimation 5s ease-in-out;
    -o-animation-fill-mode: forwards;
    animation: fadeInThenOutAnimation 5s ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes fadeOutAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@-o-keyframes fadeOutAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@-moz-keyframes fadeOutAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes fadeOutAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.animate-fade-out {
    -webkit-animation: fadeOutAnimation 3s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation: fadeOutAnimation 3s;
    -moz-animation-fill-mode: forwards;
    -o-animation: fadeOutAnimation 3s;
    -o-animation-fill-mode: forwards;
    animation: fadeOutAnimation 3s;
    animation-fill-mode: forwards;
}

.page {
    position: relative;
    width: 100%; /* Ensure the parent takes the full width of the page */
}

.background {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: -1; /* Send it to the background behind the content */
    height: 100%; /* Will grow based on the content */
}

.middle-image {
    flex-grow: 1;
    min-height: 40vw;
}

.bottom-image {
    bottom: 0;
}

.content {
    position: relative;
    z-index: 1; /* Bring the content above the background */
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
